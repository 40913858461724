import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        /*
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('sales', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        show: (cntx, { id }) => {
            axios.get(`sales/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        },
        create: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('sales', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        update: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.put(`sales/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        remove: (cntx, id) => {
            return new Promise((resolve, reject) => {
                axios.delete(`sales/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        */
        levels: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('levels', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        locations: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('locations', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        seasons: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('seasons', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        sales: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('sales', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        generateVillageSales: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('reports/village_sales', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    }
}
